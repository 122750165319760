import React from "react"
import { graphql } from "gatsby"
import MinimalLayout from "../components/Layouts/MinimalLayout"
import * as myModule from "../components/dynamic"
import Seo from "../components/Seo/seo"
function FreeRateQuote({ data }) {
  const wpData = data?.wpPage?.sections?.blocks
  console.log(wpData)
  const seo = data?.wpPage?.seo
  return (
    <MinimalLayout>
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        featuredImage={
          seo.opengraphImage?.localFile.childImageSharp.gatsbyImageData
        }
      />
      {wpData.map(row => {
        // assign component math with name
        const Component = myModule[row.fieldGroupName.split("_").slice(-1)[0]]
        return <Component key={row.fieldGroupName} {...row} />
      })}
    </MinimalLayout>
  )
}
export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoxMTU2" }) {
      seo {
        ...SeoData
      }
      sections {
        blocks {
          ... on WpPage_Sections_Blocks_CtaWithForm {
            fieldGroupName
            title
            subTitle
            form
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid(fit: COVER, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            logo {
              sourceUrl
            }
          }
          ... on WpPage_Sections_Blocks_HeroBlock {
            fieldGroupName
            journey
            title
            content
            background {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(fit: COVER, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            buttons {
              link {
                url
                title
                target
              }
            }
          }
          ... on WpPage_Sections_Blocks_ApplyNow {
            fieldGroupName
            title
            officers {
              ... on WpLoanOfficer {
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                officers {
                  position
                  nmls
                  hixonLendingNmls
                  licensedIn
                  phone {
                    title
                  }
                  email {
                    title
                    url
                  }
                  buttons {
                    link {
                      target
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default FreeRateQuote
