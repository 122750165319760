import { Link } from "gatsby"
import React from "react"
import "./layout.scss"
import { graphql, useStaticQuery } from "gatsby"
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo'

function MinimalLayout({ children }) {
  const data = useStaticQuery(graphql`
    query {
      wp {
        themeGeneralSettings {
          themeSettings {
            logo {
              sourceUrl
            }
            copy
          }
        }
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaDesc
            }
            page {
              metaDesc
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `)
  const layout = data?.wp?.themeGeneralSettings?.themeSettings

  return (
    <SEOContext.Provider value={{ global: data?.wp?.seo }}>
      <Header logo={layout.logo} />
      <main>{children}</main>
      <Footer copy={layout.copy} />
    </SEOContext.Provider>
  )
}

export default MinimalLayout
function Header({ logo }) {
  return (
    <header className="minimal-header">
      <div className="logo">
        <Link to="/">
          <img src={logo.sourceUrl} />
        </Link>
      </div>
    </header>
  )
}

function Footer({ copy }) {
  return (
    <footer className="minimal-footer">
      <h4 className="coppy-text" dangerouslySetInnerHTML={{ __html: copy }} />
    </footer>
  )
}
